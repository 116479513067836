import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "@components/shared/hero";
import { Query } from "@graphql-types";

const LegislationHero = () => {
  const { sanityLegislationPage }: Query = useStaticQuery(graphql`
    {
      sanityLegislationPage {
        hero {
          content {
            _rawRichTextContent
          }
          links {
            url
            newwindow
            linktext
            internallink
            _key
          }
        }
      }
    }
  `);

  return <Hero {...sanityLegislationPage?.hero} height="50vh" />;
};

export default LegislationHero;
