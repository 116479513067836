import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "@components/shared/sub";
import { Maybe, Query, SanityLegislations } from "@graphql-types";
import { Container, GridContainer, P } from "@util/standard";
import { MOBILE_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  margin: 155px 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 60px 0;
  }
`;

const Title = styled.p<{ bold?: boolean }>`
  font-size: 20px;
  margin: 0;
  font-family: "Playfair Display";
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const AllLegislations = () => {
  const { allSanityLegislations }: Query = useStaticQuery(graphql`
    {
      allSanityLegislations {
        nodes {
          _id
          title
          act
          description
          file {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const Legislation = (props: Maybe<SanityLegislations>) => {
    if (props == null) {
      return null;
    }
    return (
      <Container flexDirection="column">
        <Title bold>{props.title}</Title>
        <Title>{props.act}</Title>
        <P>{props.description}</P>

        <Container justifyContent="space-between">
          <Link isUnderlined {...props.link} color="darkBlue" opacity={0.5} />
          <Link
            download
            newwindow
            isUnderlined
            linktext="Download"
            url={props.file?.asset?.url}
            color="darkBlue"
            opacity={0.5}
          />
        </Container>
      </Container>
    );
  };

  const Legislations = () => (
    <GridContainer repeat={3} mobileRepeat={1} rowGap="30px" columnGap="80px">
      {allSanityLegislations.nodes.map((legislation) => {
        // @ts-ignore
        return <Legislation key={legislation?._id} {...legislation} />;
      })}
    </GridContainer>
  );

  return (
    <Wrapper>
      <Container flexDirection="column" width="70%" margin="auto">
        <Legislations />
      </Container>
    </Wrapper>
  );
};

export default AllLegislations;
