import React from "react";
import { graphql, PageProps } from "gatsby";

import ContactUsBlock from "@components/contact/contactUsBlock";
import SEO from "@components/shared/seo";
import LegislationHero from "@components/legislation/legislationHero";
import AllLegislations from "@components/legislation/allLegislations";
import { Query } from "@graphql-types";
interface Props extends PageProps {
  data: Query;
}

export default function Legislation({ data }: Props) {
  const { sanityLegislationPage } = data;

  return (
    <div>
      <SEO seoData={sanityLegislationPage?.seo} />
      <LegislationHero />
      <AllLegislations />
      <ContactUsBlock />
    </div>
  );
}

export const query = graphql`
  query LegislationQuery {
    sanityLegislationPage {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
